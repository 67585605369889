import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';
import createEmotionCache from 'src/utils/createEmotionCache';
import MotionLazyContainer from '../src/components/animate/MotionLazyContainer';
import ProgressBar from '../src/components/ProgressBar';
import ThemeProvider from '../src/theme';
import * as gtag from '../utils/gtag';

// ----------------------------------------------------------------------

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <DefaultSeo {...SEO} />

        <ThemeProvider>
          <MotionLazyContainer>
            <ProgressBar />
            {getLayout(<Component {...pageProps} />)}
          </MotionLazyContainer>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

// // Web Vitals
// export function reportWebVitals(metric: { label: string; }) {
//   console.log(metric)
// }
