// icons
import closeIcon from '@iconify/icons-carbon/close';
// @mui
import { Box, CircularProgress } from '@mui/material';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { ReactPlayerProps } from 'react-player';
//
import { DialogAnimate, IconButtonAnimate } from '../animate';
import Iconify from '../Iconify';

const DynamicPlayer = dynamic(() => import('./Player'), {
  loading: () => <CircularProgress
    sx={{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      m: 'auto',
      zIndex: -1,
      position: 'absolute',
    }}
  />
})


// ----------------------------------------------------------------------

interface Props extends ReactPlayerProps {
  videoPath: string;
  open: boolean;
  onClose: VoidFunction;
}

export default function PlayerWithButton({ videoPath, open, onClose, ...other }: Props) {
  const [loading, setLoading] = useState(true);

  const onReady = () => {
    setLoading(false);
  };

  return (
    <DialogAnimate
      fullScreen
      open={open}
      sx={{ bgcolor: 'transparent' }}
    >
      <Box sx={{ position: 'fixed', top: 24, right: 24, zIndex: 9 }}>
        <IconButtonAnimate
          size="large"
          onClick={onClose}
          sx={{
            bgcolor: 'common.white',
            '&:hover': {
              color: 'primary.main',
              bgcolor: 'common.white',
            },
          }}
        >
          <Iconify icon={closeIcon} sx={{ width: 24, height: 24 }} />
        </IconButtonAnimate>
      </Box>

      {loading && (
        <CircularProgress
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            m: 'auto',
            zIndex: -1,
            position: 'absolute',
          }}
        />
      )}

      <DynamicPlayer
        url={videoPath} playsinline playing controls onReady={onReady} {...other} />
    </DialogAnimate>
  );
}
